import service from '../../utils/request.js'

// 客服信息
export function subpage() {
  return service({
    url: '/website/contactInformation/get',
    method: 'get'
  })
}
// 备案信息
export function keepon() {
  return service({
    url: '/website/archivalInformation/get',
    method: 'get'
  })
}
// 网底信息
export function notBottm() {
  return service({
    url: '/website/datadictionarydirectory/getLink',
    method: 'get'
  })
}
