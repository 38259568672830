<template>
  <div style="width:100vw;height:100vh;overflow-x:hidden" class="website-wrapper">
    <el-backtop target=".website-wrapper" />
    <!--  -->
    <div class="header clearfix">
      <h2>
        <img src="../assets/logo.png" alt>
      </h2>
      <div class="nav-bar clearfix">
        <!-- 判断当前路径，或者当前路径urls下有的路径 -->
        <span
          v-for="item in data"
          :key="item.name"
          :class="{ 'active': item.path === curPath || item.urls.indexOf(curPath) !== -1 }"
          @click="handleClick(item.children.length === 0 ? item.path : '')"
        >
          <a v-if="item.children.length === 0">{{ item.meta.title }}</a>
          <el-dropdown v-else>
            <span class="el-dropdown-link">
              <a>{{ item.meta.title }}</a>
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="childrenItem in item.children" :key="childrenItem.id">
                <div
                  @click="handleCommand(childrenItem.path, item.path)"
                >{{ childrenItem.meta.title }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </div>
      <!-- <div style="margin:auto 0;width: 260px;text-align:right;margin-right:10px;"> -->
      <!-- <el-button type="text" @click="toAdmin">进入后台</el-button> -->
      <!-- </div> -->
    </div>
    <!--  -->
    <div class="app-main">
      <RouterView />
    </div>
    <div class="fixed">
      <div class="customer">
        <div class="customers">
          <img src="../assets/kf.png" alt>
          <div class="">在线客服</div>
        </div>
      </div>
      <div class="customer">
        <div class="customers">
          <img src="../assets/ys.png" alt>
          <div class="">预约演示</div>
        </div>
      </div>
      <template>
        <el-popover placement="left" trigger="hover" width="50">
          <div slot="reference" class="customer">
            <div class="customers">
              <img src="../assets/erwm.png" alt>
              <div class="">点击关注</div>
            </div>
          </div>
          <div style="text-align: center;">
            <img src="../assets/gzh.jpg" alt style="width:100px;height:100px;">
          </div>
        </el-popover>
      </template>
      <div class="appointment" />
      <div class="clickFollow" />
    </div>
    <div class="bottom">
      <div class="content">
        <div class="contens">
          <div class="span1">湖南和仲电子科技有限公司</div>
          <br>
          <div class="span2">
            和风度穹闾，仲春江山丽！
            <br>智慧社会已来
            我们期望与各行业的合作伙伴紧密携手，共创美好未来。
          </div>
          <br>
          <div class="span3">
            <template>
              <el-popover placement="bottom" width="100" trigger="hover">
                <div slot="reference">
                  <img
                    src="@/assets/wechat.png"
                    alt
                    width="30px"
                    height="30px"
                    style="background:rgb(40,196,69);border-radius:50%;padding:5px;cursor:pointer"
                  >
                </div>
                <div style="text-align: center;">
                  <img :src="newsdata.vchat" style="width:100px;height:100px;">
                </div>
              </el-popover>
            </template>
            <!-- <template>
              <el-popover placement="bottom" width="200" trigger="hover">
                <div slot="reference">
                  <img
                    src="@/assets/qq.png"
                    alt
                    width="30px"
                    height="30px"
                    style="background:rgb(235,80,43);border-radius:50%;padding:5px"
                  >
                </div>
                <div>
                  {{ newsdata.qq }}&nbsp;&nbsp;
                  <el-button
                    v-clipboard:copy="newsdata.qq"
                    v-clipboard:success="clipboardSuccessHandler"
                    v-clipboard:error="clipboardErrorHandler"
                    type="text"
                  >复制</el-button>
                </div>
              </el-popover>
            </template>-->
            <template>
              <a href="mailto:111111@qq.com">
                <el-popover
                  placement="bottom"
                  width="200"
                  trigger="click"
                  :disabled="true"
                  @click="getemail"
                >
                  <div slot="reference">
                    <img
                      src="@/assets/yx.png"
                      alt
                      width="30px"
                      height="30px"
                      style="background:rgb(255,158,62);border-radius:50%;padding:5px;cursor:pointer"
                    >
                  </div>
                  <!-- <div>
                  {{ newsdata.email }}
                  <el-button
                    v-clipboard:copy="newsdata.email"
                    v-clipboard:success="clipboardSuccessHandler"
                    v-clipboard:error="clipboardErrorHandler"
                    type="text"
                  >复制</el-button>
                  </div>-->
                </el-popover>
              </a>
            </template>
            <template>
              <el-popover placement="top" width="200" trigger="hover">
                <div slot="reference">
                  <img
                    src="@/assets/dh.png"
                    alt
                    width="30px"
                    height="30px"
                    style="background:rgb(160,207,255);border-radius:50%;padding:7px;cursor:pointer"
                  >
                </div>
                <div>
                  {{ newsdata.phone }}&nbsp;&nbsp;
                  <el-button
                    v-clipboard:copy="newsdata.phone"
                    v-clipboard:success="clipboardSuccessHandler"
                    v-clipboard:error="clipboardErrorHandler"
                    type="text"
                  >复制</el-button>
                </div>
              </el-popover>
            </template>
          </div>
        </div>
        <div class="product">
          <div class="produfont1">产品与服务</div>
          <br>
          <div v-for="item in notdata" :key="item.id" class="produfont2">
            <a :href="item.value" target="blank">{{ item.name }}</a>
          </div>
          <br>
        </div>
        <div class="aboutmy">
          <div class="aboutmy1">关于我们</div>
          <br>
          <div v-for="item in ideadata" :key="item.id" class="aboutmy2">
            <a :href="item.value" target="blank">{{ item.name }}</a>
          </div>
          <br>
        </div>
        <div class="case">
          <div class="case1">我们的案例</div>
          <br>
          <div v-for="item in casedata" :key="item.id" class="case2">
            <a :href="item.value" target="blank">{{ item.name }}</a>
          </div>
          <br>
        </div>
      </div>
      <div class="information" style="display: flex;">
        <span style="margin-left: auto;">{{ newsdatakeep.forTheRecordTime }}©版权所有</span>&nbsp;&nbsp;&nbsp;&nbsp;
        <div style="display: inline-block;">
          <img src="../assets/police.png" alt="" style="width: 18px;height: 18px;">&nbsp;&nbsp;
        </div>
        <a
          href="http://www.beian.miit.gov.cn/"
          target="blank"
          style="    margin-right: auto;"
        >

          {{ newsdatakeep.internetContentProvider }}</a>&nbsp;&nbsp;&nbsp;&nbsp;
        <!-- <span>{{ newsdatakeep.forTheRecordAddress }}</span> -->
      </div>
    </div>
  </div>
</template>
<script>
import { getMenu } from '../api/pc/home'
import { subpage, keepon, notBottm } from '../api/pc/index'

export default {
  data() {
    return {
      data: [],
      newsdata: {},
      newsdatakeep: {},
      notdata: {},
      ideadata: {},
      casedata: {},
      curPath: '',
      logined: false,
      value: ''
    }
  },
  created() {
    this.getData()
    this.getkeepData()
    this.getnotBottm()
    this.curPath = this.$route.path
    getMenu()
      .then(res => {
        console.log('res', res)
        this.data = res.data.menuList
        this.data.map(o => {
          // 遍历出来urls里的路径是否存在
          o.urls = o.children.map(child => child.path)
        })
        console.log('this.data', this.data)
      })
      .catch(e => {
        console.error(e)
      })
  },
  methods: {
    handleClick(path) {
      if (!path) {
        return
      }
      this.curPath = path
      this.$router.push(path)
    },
    toAdmin() {
      this.$router.push('/admin/home')
    },
    // 消息数据
    getData() {
      subpage()
        .then(res => {
          console.log('res subpage', res)
          this.newsdata = JSON.parse(JSON.stringify(res.data.contactInformation))
          console.log('newsdata', this.newsdata)
        })
        .catch(e => {
          console.error(e)
        })
    },
    // 备案数据
    getkeepData() {
      keepon()
        .then(res => {
          console.log('res', res)
          this.newsdatakeep = res.data.archivalInformation
        })
        .catch(e => {
          console.error(e)
        })
    },
    // 获取网底信息
    getnotBottm() {
      notBottm()
        .then(res => {
          console.log('res', res)
          this.notdata = res.data.item.productAndServe
          this.ideadata = res.data.item.aboutUs
          this.casedata = res.data.item.usCase
        })
        .catch(e => {
          console.error(e)
        })
    },
    // Success event handler
    clipboardSuccessHandler({ value, event }) {
      this.$message.success('复制成功')
    },
    // Error event handler
    clipboardErrorHandler({ value, event }) {
      this.$message.error('复制失败')
    },
    // 关于和仲跳转路径
    handleCommand(path, parentPath) {
      this.$router.push(path)
      this.curPath = parentPath
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  height: 80px;
  display: flex;
  h2 {
    float: left;
    margin: 0;
    margin-top: 10px;
    margin-left: 20px;
    img {
      display: block;
      width: 260px;
      height: 60px;
      object-fit: contain;
    }
  }
  .nav-bar {
    margin: auto 0;
    flex: 1;
    text-align: center;
    // padding-left: 600px;
    // padding-top: 25px;
    span {
      margin: 0 10px;
      // float: left;
      // height: 30px;
      padding: 5px 5px;

      border-radius: 18px;
      // color: #333;
      ::v-deep {
        .el-dropdown {
          color: #333;
        }
      }
      a {
        display: inline-block;
        padding: 0 15px;
        line-height: 30px;
        text-align: center;
        font-size: 15px;
      }
    }
    span:hover {
      background-color: #54b6ff;
      color: #fff;
    }
    span.active {
      background-color: #54b6ff;
      color: #fff;
    }
    .active {
      ::v-deep {
        .el-dropdown {
          color: #fff;
        }
      }
    }
  }
}
.bottom {
  width: 100%;
  height: 350px;
  background: #031830;
  color: #ffffff;
  .content {
    display: flex;
    .product {
      display: inline-block;
      margin-left: 268px;
      margin-top: 42px;
      .produfont1 {
        font-size: 20px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 24px;
      }
      .produfont2 {
        font-size: 14px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 40px;
      }
    }
    .contens {
      display: inline-block;
      margin-left: auto;
      margin-top: 42px;
      span {
        color: #ffffff;
      }
      .span1 {
        font-size: 28px;
        font-family: Helvetica;
        line-height: 34px;
        font-weight: bold;
      }
      .span2 {
        font-size: 17px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 26px;
        margin-top: 10px;
      }
      .span3 {
        margin-top: 30px;
        font-family: Helvetica;
        font-weight: 500;
        color: #ffffff;
        line-height: 37px;
      }
    }
    .aboutmy {
      display: inline-block;
      margin-left: 134px;
      margin-top: 42px;
      .aboutmy1 {
        font-size: 20px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 24px;
      }
      .aboutmy2 {
        font-size: 14px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 40px;
      }
    }
    .case {
      display: inline-block;
      margin-right: auto;
      margin-left: 134px;
      margin-top: 42px;
      .case1 {
        font-size: 20px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 24px;
      }
      .case2 {
        font-size: 14px;
        font-family: Helvetica;
        color: #ffffff;
        line-height: 40px;
      }
    }
  }
  .information {
    text-align: center;
    margin-top: 40px;
  }
}
</style>
<style lang="scss" >
.span3 {
  .el-popover__reference {
    width: 32px;
    display: inline;
    margin: 10px;
  }
}
.fixed {
  position: fixed;
  right: 17px;
  bottom: 200px;
  z-index: 999;
  .customer {
    background-color: rgb(62, 62, 62);
    text-align:center;
    color:#fff;
    padding: 10px;
    margin-top: 5px;
    cursor:pointer;
    font-size: 12px;
  }
  .customers {
      img {
        width: 25px;
        height: 25px;
      }
    }
}
</style>

